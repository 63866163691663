//backgroundColors
$signIn-background: #f8f8f8;
$signImage-background: #001320;

//Colors
$logoName-color: #1bba6e;
$logoDesc-color: #e0e0e0;
$welcome-color: rgba(0, 0, 0, 0.49);
$sign-in-text-color: #075e54;
$signInLabel-color: #001320;
$profileHeading-color: #000415;

//fontSize
$font-75: 75px;
$font-45: 45px;
$font-40: 40px;
$font-34: 34px;
$font-30: 30px;
$font-18: 18px;
$font-25: 25px;
$font-20: 20px;
$font-16: 16px;
$font-15: 15px;
$font-12: 12px;
$font-13: 13px;
$font-18: 18px;

//fontWeight
$font-500: 500;
$font-600: 600;
$font-700: 700;
$font-450: 450;
//stylefont
// $font-fam-Marmelat: 'Roboto', sans-serif;
$font-normal: normal;

//lineheight

$line-height-41: 41px;
$line-height-54: 54px;

// mixins

@mixin discard-btn {
    border: none;
    width: 100%;
    height: 40px;
    background: #e9e9e9;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: #001320;
    font-family: 'AirbnbCereal_W_Md';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
}

@mixin save-btn {
    width: 100%;
    height: 40px;
    border: none;
    background: #1bba6e;
    border-radius: 2px;
    color: #ffffff;
    font-family: 'AirbnbCereal_W_Md';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
}

@mixin save-btn-hover {
    color: #1bba6e;
    border: 1px solid #1bba6e;
    background: #ffffff;
}

@mixin cancel-btn {
    width: 100%;
    height: 40px;
    border: none;
    background: #ea4f41;
    border-radius: 2px;
    color: #ffffff;
    font-family: 'AirbnbCereal_W_Md';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
}

@mixin cancel-btn-hover {
    color: #ea4f41;
    border: 1px solid #ea4f41;
    background: #ffffff;
}
