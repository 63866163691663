@import '~antd/dist/antd.less';

.collapsed {
    display: none;
    margin-left: 45px !important;
    transition: all 1s linear !important;
    font-size: 19px;
}

.centerSpinnerForLazyComponents {
    position: fixed;
    top: 45%;
    left: 49%;
    right: 49%;
    z-index: 1;
}
.spinnerComp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedbacktruncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-container .gm-style-mtc-bbw .gm-style-mtc > button{
    font-size: clamp(12px, 1.5vw, 14px) !important;
    padding: 5px 10px !important;
    height: 30px !important;
}
.map-container .gm-style .gm-fullscreen-control{
    font-size: clamp(12px, 1.5vw, 14px) !important;
    padding: 5px 10px !important;
    height: 30px !important;
    width: 30px !important;
}
.map-container .gm-style .gm-fullscreen-control img{
    width: 16px !important;
    height: 16px !important;
}
.map-container .gm-style .gm-bundled-control-on-bottom .gmnoprint{
    left:10px !important;
    bottom: 150px !important;
    z-index: 999999999999999;
}
.map-container .gm-style .gm-bundled-control-on-bottom .gmnoprint > div{
    height: 60px !important;
    width: 30px !important;
}
.map-container .gm-style .gm-bundled-control-on-bottom .gmnoprint button{
    width: 30px !important;
    height: 30px !important;
}
.map-container .gm-style .gm-bundled-control-on-bottom .gm-svpc{
    width: 30px !important;
    height: 30px !important;
    left: 10px !important;
}
.map-container .gm-style .gm-bundled-control-on-bottom .gm-svpc img{
   width: 14px !important;
}
.map-container .gm-style .gm-bundled-control-on-bottom .gmnoprint > div > button > img{
    width: 14px !important;
}
@hack: true; @import "/home/zaki-mantiqh/Ecocrew/web-admin/ant-design-less-files/theme.less";