@import '../../../styles/variables.scss';

.mainContainer {
    background-color: $signIn-background;
    min-height: 100vh !important;
    align-items: center;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        .formContent {
            background-color: $signIn-background;
            width: 100%;
            height: 100%;

            .SignInForm {
                margin-left: 120px !important;
                display: flex;
                justify-content: center;
                flex-direction: column;

                @media (max-width: 768px) {
                    justify-content: none !important;
                }

                .forgotText {
                    font-family: 'AirbnbCereal_W_Md', sans-serif;
                    letter-spacing: 1px;
                    font-style: normal;
                    font-weight: 450;
                    font-size: 35px;
                    line-height: 42px;
                    color: #075e54;
                }
                .forgotPara {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    max-width: 400px;
                    color: #455a64;
                    margin-bottom: 40px;
                }
                .welcome {
                    font-size: $font-16;
                    letter-spacing: 0.9px;
                    font-weight: $font-450;
                    line-height: 18px;
                    color: $welcome-color;
                    margin-bottom: 10px;
                }
                .signInText {
                    font-size: $font-45;
                    font-weight: 450;
                    line-height: 54px;
                    color: $sign-in-text-color;
                    margin-bottom: 30px;
                    font-family: 'AirbnbCereal_W_Md';
                }
                .form {
                    label {
                        // font-size: $font-18;
                        color: $signInLabel-color;
                        font-weight: $font-500;
                        margin-bottom: 7px;
                        margin-top: 15px;
                        font-style: normal;

                        font-size: 16px;
                        line-height: 24px;
                    }
                    .inputContainer {
                        padding: 10px;
                        width: 460px;
                        border-radius: 15px;
                        border: 2px solid rgba(0, 0, 0, 0.18);
                        background: #ffffff;
                    }
                    .forgotLink {
                        font-weight: $font-500;
                        font-size: $font-16;
                        line-height: 24px;
                        text-decoration-line: underline;
                        color: #1bba6e;
                    }
                    .submitBtn {
                        width: 590px;
                        height: 60px;
                        border: none;
                        margin-top: 90px;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
                        font-size: $font-25;
                        line-height: 30px;
                        color: white;
                        background: #075e54;
                        border: 1px solid #e8e8e852;
                        border-radius: 35px;
                    }
                }
            }
            @media (max-width: 1440px) {
                .SignInForm {
                    .form {
                        .inputContainer {
                            width: 420px !important;
                        }
                        .submitBtn {
                            width: 539px;
                            height: 60px;
                        }
                    }
                }
            }
        }
        .imageContent {
            height: 100vh;
            position: relative;
            background-color: $signImage-background;

            .image1 {
                position: absolute;
                top: 38px;
                img {
                    width: 150px;
                }
            }
            .image2 {
                position: absolute;
                right: 0;
                img {
                    width: 236px;
                }

                .image2Inner {
                    position: absolute;
                    top: 52px;
                    right: 140px;
                    width: 65px;
                }
            }
            .image3 {
                position: absolute;
                bottom: 0;
                img {
                    width: 137px;
                }
            }
            .image4 {
                img {
                    width: 317px;
                    height: auto;
                }
                position: absolute;
                bottom: 0;
                right: 0;

                .image4Inner {
                    position: absolute;
                    bottom: 18px;
                    right: 33px;
                    width: 70px;
                }
            }
            .angleCircle {
                display: none !important;
                width: 100%;
                position: absolute;
                bottom: 15px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .circle {
                    .btn {
                        font-size: 35px;
                        color: #1bba6e;
                        margin-bottom: 25px;
                    }
                }
                .text {
                    h4 {
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                        font-size: $font-15;
                        color: white;
                    }
                }
            }
            .logoContainer {
                position: absolute;
                top: 32%;
                left: 50%;
                right: 50%;

                .logoImage {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around !important;
                    align-items: center;

                    .imgLogo {
                        width: 130px;
                        height: 140px;
                        margin-bottom: 33px;
                    }
                    .logoName {
                        margin-bottom: 25px;
                    }
                    .logoDesc {
                        width: 400px;
                    }
                }
            }
        }

        @media (min-width: 1920px) and (max-width: 2700px) {
            .formContent {
                .SignInForm {
                    margin-left: 250px;
                }
                .imageContent {
                    .logoContainer {
                        .logoImage {
                            .imgLogo {
                                width: 180px !important;
                                height: 140px;
                            }
                            .logoName {
                                margin-bottom: 25px;
                            }
                            .logoDesc {
                                width: 500px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1110px) {
            display: block;
            width: 100%;
            .SignInForm {
                padding: 0 80px;
                width: 100vw;
                height: 100vh;
            }
            .imageContent {
                width: 100vw;
                height: 100vh;
            }
            overflow-x: hidden;
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column-reverse;
            .formContent {
                margin: 0;
                width: 100% !important;
                padding: 0;
                .SignInForm {
                    width: 100% !important;
                    margin: 0;
                    justify-content: none !important;
                    padding: 50px 25px;
                    margin-left: 0 !important;

                    .form {
                        margin-left: 0;
                        padding: 0 !important;
                        margin: 0 !important;
                        .inputContainer {
                            width: 100% !important;
                            max-width: 600px;
                        }
                        .submitBtn {
                            max-width: 650px;
                            width: 100% !important;
                        }
                    }
                }
            }
            .imageContent {
                object-fit: cover;
                object-position: bottom;
                .angleCircle {
                    display: inline !important;
                }

                .logoContainer {
                    .logoImage {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around !important;
                        align-items: center;

                        .imgLogo {
                            width: 80px;
                            height: auto;
                            margin-bottom: 33px;
                        }
                        .logoName {
                            width: 190px;
                            margin-bottom: 25px;
                        }
                        .logoDesc {
                            width: 250px;
                        }
                    }
                }
                .image1 {
                    img {
                        width: 80px !important;
                    }
                }
                .image2 {
                    img {
                        width: 140px !important;
                    }

                    .image2Inner {
                        top: 32px;
                        right: 80px;
                        width: 35px !important;
                    }
                }
                .image3 {
                    img {
                        width: 80px !important;
                    }
                }
                .image4 {
                    img {
                        width: 197px !important;
                        height: auto;
                    }

                    .image4Inner {
                        right: 23px;
                        width: 35px !important;
                    }
                }
            }
        }

        @media (max-width: 1620px) {
            .formContent {
                .SignInForm {
                    margin-left: 50px;
                    .form {
                        label {
                            font-size: 16px;
                        }
                        .inputContainer {
                            padding: 8px;
                            width: 430px;
                        }
                        .submitBtn {
                            width: 450px;
                        }
                    }
                }
            }
            .imageContent {
                .image1 {
                    img {
                        width: 130px;
                    }
                }
                .image2 {
                    img {
                        width: 210px;
                    }
                    .image2Inner {
                        width: 46px;
                    }
                }
                .image3 {
                    img {
                        width: 119px;
                    }
                }
                .image4 {
                    img {
                        width: 295px;
                    }
                    .image4Inner {
                        width: 60px;
                    }
                }
                .imgLogo {
                    width: 115px;
                }
                .imageName {
                    width: 300px;
                }
                .imageDesc {
                    width: 350px;
                }
            }
        }
        @media (max-width: 460px) {
            .formContent {
                margin-top: 40px;
                .SignInForm {
                    .form {
                        .submitBtn {
                            margin-top: 30px;
                        }
                    }
                }
            }
            .imageContent {
                min-height: 560px;
                max-height: 92vh;
            }
        }
    }
}
