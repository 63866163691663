/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
@import './styles/colors.less';

@theme: default;

// The prefix to use on all css classes from ant.
@ant-prefix: ant;

// An override for the html selector for theme prefixes
@html-selector: html;

// [CSS-VARIABLE-REPLACE-BEGIN: html-variables]
// [CSS-VARIABLE-REPLACE-END: html-variables]

@primary-color: #001320; // primary color for all components
@secondary-color: #1bba6e; // primary color for all components
@redRound-color: #ff0000;
@link-color: #c2c2c2; // link color
@table-gray-bakground: #fafafa; // link color
@table-header-text: #717171; // link color
@success-color: #65a745; // success state color
@warning-color: #fa7014; // warning state color
@error-color: #ff000d; // error state color
@font-size-base: 14px; // major text font size
@menu-item-color: #ffffff;
@menu-highlight-color: #1bba6e;
@menu-item-active-bg: #001320;
@menu-item-active-color: red;
@layout-sider-background: @link-color; //layout sider color
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgb(0, 0, 0); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 1px; // major border radius
@border-color-base: #bdbdbd; // major border color
@table-header-bg: @table-gray-bakground; //table header bg-color
@table-header-color: @table-header-text; //table header color
@box-shadow-base: 0 3px 6px -4px rgb(0, 0, 0), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@select-item-selected-color: #c2c2c2;
@select-item-selected-bg: @primary-color;
@tooltip-color: @menu-item-color;
@tooltip-bg: @primary-color;
@table-footer-color: @heading-color;
@input-placeholder-color: #8c8c8c;
@input-color: #001320;
@input-disabled-color: #001320;
@input-disabled-bg: #e0e0e0;
@menu-inline-submenu-bg: rgba(255, 0, 0, 0);
@menu-item-active-bg: #b8d5cd;
@calendar-column-active-bg:rgb (255, 0, 0);
@tabs-title-font-size: clamp(16px, 2vw, 20px);
@divider-color: rgba(128, 128, 128);
@switch-color: @secondary-color;
@tabs-ink-bar-color: @secondary-color;
@tabs-hover-color: @secondary-color;
@label-color: #8a8a8a;
@input-height-base: 40px;
@input-height-lg: 50px;
@input-height-sm: 30px;
