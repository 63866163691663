/* @import './fonts/otf/AirbnbCereal_W_Md.otf'; */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Yanone+Kaffeesatz:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marmelad&family=Nanum+Gothic&family=Yanone+Kaffeesatz:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai&family=Marmelad&family=Nanum+Gothic&family=Yanone+Kaffeesatz:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
}

@font-face {
    font-family: 'AirbnbCereal_W_Md';
    src: local('AirbnbCereal_W_Md'), url('./assets/fonts/otf/AirbnbCereal_W_Md.otf') format('opentype');
    font-weight: normal;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    overflow-x: hidden !important;
    font-family: 'AirbnbCereal_W_Md' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: 'Almarai', sans-serif;
    font-family: 'Marmelad', sans-serif; */
}

/* body {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    overflow-x: hidden !important;
    font-family: 'Nanum Gothic', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Didact Gothic', 'Mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Almarai', sans-serif;
    font-family: 'Marmelad', sans-serif;
} */
.ant-table {
    color: #001320 !important;
    font-family: 'AirbnbCereal_W_Md';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Nanum Gothic', 'Courier New', monospace;
}

@media (max-width: 768px) {
    .ant-layout-sider-collapsed {
        width: 80% !important;
    }

    .ant-layout-sider-children .ant-menu-inline-collapsed {
        width: 0% !important;
    }

    .ant-layout-sider {
        width: 520px;
        background: none !important;
    }
}

.ant-input[disabled] {
    border: none !important;
    border-radius: 2px;
    font-family: 'AirbnbCereal_W_Md';
}

.ant-tabs.ant-tabs-top > .ant-tabs-nav::before {
    border: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 46px !important;
    background: #ffffff !important;
    border: 1px solid #ffffff !important;
    color: grey !important;
}

.ant-table-thead > tr {
    box-shadow: 1px 2px 2px 2px rgb(206, 206, 206);
}

.ant-input-group {
    font-family: 'AirbnbCereal_W_Md';
    font-size: 20px !important;
}

.ant-select {
    font-family: 'AirbnbCereal_W_Md';
    font-size: 16px !important;
}

.tblBtnViewDetals {
    text-decoration: underline #1890ff;
    color: #1890ff;
}

.tblBlock {
    text-decoration: underline#EE6565;
    color: #ee6565;
}

.tblUnblock {
    text-decoration: underline #52c41a;
    color: #52c41a;
}

.removeBackground {
    border: none !important;
    background-color: #ffffff !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 2px solid #1bba6e !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1bba6e !important;
}

.ant-tabs-tab:hover .round_order {
    background-color: #1bba6e !important;
}

.ant-tabs-tab.ant-tabs-tab-active .round_order {
    background-color: #1bba6e !important;
}

.ant-input[disabled] {
    background-color: white !important;
    border: darkgrey 1px solid !important;
    /* border-color: darkgrey !important; */
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #52c41a !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #52c41a !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #717171 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #52c41a !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #52c41a !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon > .ant-steps-icon-dot {
    background: #52c41a !important;
    scale: 1.3;
}

.occsteps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    width: 100%;
    min-width: 120px;
}

.occsteps .ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 123px;
}

.occsteps {
    margin-bottom: 50px;
    padding: 15px 0;
    overflow-x: auto;
}

.ant-table-content::-webkit-scrollbar {
    width: auto;
    height: 4px;
}

/* Track */
.ant-table-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(208, 248, 246);
    border-radius: 10px;
}

/* Handle */
.ant-table-content::-webkit-scrollbar-thumb {
    background: #5a8460;
    border-radius: 10px;
}

/* Handle on hover */
.ant-table-content::-webkit-scrollbar-thumb:hover {
    background: #1f772c;
}

::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}
.accessibleDropDown .ant-cascader-menu {
    min-height: 270px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(226, 255, 219);
    border-radius: 10px;
}

.ant-space-item {
    width: 100%;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #5a8460;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1f772c;
}

.actionIcons {
    width: 100%;
    max-width: 150px;
    background: white !important;
    color: #000415 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    border: none !important;
}

.viewDetailsButton {
    background-color: 'white' !important;
    border-color: 'black' !important;
}

.marker-icon {
    background-color: yellow !important;
    border-radius: 50% !important;
    padding: 5px !important;
}

.map-label {
    margin-top: 56px;
    font-size: 10px;
    font-family: 'AirbnbCereal_W_Md';
    font-weight: 600;
    background: #001320;
    color: #ffffff !important;
    padding: 3px 5px;
    border-radius: 5px;
}
.status-tag.ant-tag {
    padding: 3px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    width: max-content;
    border-radius: 4px;
    font-weight: 700;
    font-size: clamp(11px, 1.2vw, 14px);
    line-height: 1;
}
.status-tag.ant-tag .status-tag-img {
    width: 15px;
}
@media screen and (min-width: 800px) {
    .status-tag.ant-tag .status-tag-img {
        width: 20px;
    }
}
.custom-placed.ant-tag-has-color {
    color: #b2790b;
    border: 1px solid #cbb998;
    background: #fcfff0 !important;
}
.custom-unloaded.ant-tag-has-color {
    color: #1890ff;
    border: 1px solid #91d5ff;
    background: #e6f7ff !important;
}
.custom-assigned.ant-tag-has-color {
    color: #1890ff;
    border: 1px solid #749eb8;
    background: #e8f2f7 !important;
}
.custom-onroute.ant-tag-has-color {
    color: #fadb14;
    border: 1px solid #fffb8f;
    background: #feffe6 !important;
}
.custom-arrived.ant-tag-has-color {
    color: #faad14;
    border: 1px solid var(--calendula-gold-3, #ffe58f);
    background: var(--calendula-gold-1, #fffbe6) !important;
}
.custom-inprogress.ant-tag-has-color {
    color: #fa8c16;
    border: 1px solid var(--sunset-orange-3, #ffd591);
    background: var(--sunset-orange-1, #fff7e6) !important;
}
.custom-closed.ant-tag-has-color {
    color: #722ed1;
    border: 1px solid var(--golden-purple-3, #d3adf7);
    background: var(--golden-purple-1, #f9f0ff) !important;
}
.custom-unloading.ant-tag-has-color {
    color: #eb2f96;
    border: 1px solid var(--magenta-3, #ffadd2);
    background: var(--magenta-1, #fff0f6) !important;
}
.custom-reached.ant-tag-has-color {
    color: #a0d911;
    border: 1px solid var(--lime-3, #eaff8f);
    background: var(--lime-1, #fcffe6) !important;
}
.custom-unloaded.ant-tag-has-color {
    color: #1890ff;
    border: 1px solid var(--day-break-blue-3, #91d5ff);
    background: var(--day-break-blue-1, #e6f7ff) !important;
}
.custom-completed.ant-tag-has-color {
    color: #52c41a;
    border: 1px solid var(--polar-green-3, #b7eb8f);
    background: var(--polar-green-1, #f6ffed) !important;
}
.custom-cancel.ant-tag-has-color {
    color: #f5222d;
    border: 1px solid var(--dust-red-3, #ffa39e);
    background: var(--dust-red-1, #fff1f0) !important;
}

.display-none {
    display: none !important;
}

#map-comp div[role='menubar'] > div {
    display: none;
}
#map-comp div[role='menubar'] > div:first-child,
#map-comp div[role='menubar'] > div:last-child {
    display: block;
}

/* input close btn style */
.ant-select-clear {
    top: 36% !important;
    right: 4px !important;
    width: 25px !important;
    height: 25px !important;
    font-size: 22px !important;
    z-index: 2 !important;
}
.ant-input-clear-icon {
    font-size: 22px !important;
}
.ant-input-group {
    line-height: 1 !important;
}
.ant-input-search .ant-input-affix-wrapper {
    padding: 2px 11px !important;
}
.ant-picker-clear {
    right: 0px !important;
    font-size: 22px !important;
    z-index: 2 !important;
}
.ant-picker-range .ant-picker-clear {
    right: 4px !important;
}
